import { connect } from 'react-redux';
import component from './component';
import { closeSnackbar, removeSnackbar } from '../../store/actions/snackbar';
import { getSnackbarNotifications } from '../../store/selectors/snackbar';

const mapStateToProps = (state) => ({
  snackbar: getSnackbarNotifications(state),
});

const mapDispatchToProps = (dispatch) => ({
  closeSnackbar: (key) => dispatch(closeSnackbar(key)),
  removeSnackbar: (key) => dispatch(removeSnackbar(key)),
});

const Container = connect(
    mapStateToProps,
    mapDispatchToProps,
)(component);

export default Container;
