import { createSelector } from 'reselect';

const getFiberySection = state => state.fibery;

const getFiberyRows = createSelector(
    [getFiberySection],
    (fibery) => fibery.rows,
);

const getFiberyRowsLastUpdate = createSelector(
    [getFiberySection],
    (fibery) => fibery.lastUpdate,
);

const getFiberyRowsStatus = createSelector(
    [getFiberySection],
    (fibery) => fibery.status,
);

export {
  getFiberyRows,
  getFiberyRowsLastUpdate,
  getFiberyRowsStatus,
};
