const types = {
  setSettingsProjectId: 'Settings/ProjectId/Set',
  setSettingsTeamId: 'Settings/Team/Set',
  setSettingsSpecialistId: 'Settings/SpecialistId/Set',
  setSettingsPartnerId: 'Settings/PartnerId/Set',
  setSettingsSchemas: 'Settings/Schemas/Set',
  setAuthUserData: 'Settings/AuthUserData/Set',
  setProjectsConfig: 'Settings/ProjectsConfig/Set',
};

const setSettingsProjectId = ({ project }) => ({
  type: types.setSettingsProjectId,
  payload: {
    project,
  },
});

const setSettingsTeamId = ({ team }) => ({
  type: types.setSettingsTeamId,
  payload: {
    team,
  },
});

const setAuthUserData = ({ user }) => ({
  type: types.setAuthUserData,
  payload: {
    user,
  },
});

const setSettingsSpecialistId = ({ specialist }) => ({
  type: types.setSettingsSpecialistId,
  payload: {
    specialist,
  },
});

const setSettingsPartnerId = ({ partner }) => ({
  type: types.setSettingsPartnerId,
  payload: {
    partner,
  },
});

const setSettingsSchemas = ({ schemas }) => ({
  type: types.setSettingsSchemas,
  payload: {
    schemas,
  },
});

const setProjectsConfig = ({ projectsConfig }) => ({
  type: types.setProjectsConfig,
  payload: {
    projectsConfig,
  },
});

export {
  types,

  setSettingsProjectId,
  setSettingsTeamId,
  setAuthUserData,
  setSettingsSpecialistId,
  setSettingsPartnerId,
  setSettingsSchemas,
  setProjectsConfig,
};
