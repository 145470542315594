import { timeToFloat } from './filter';

const getField = (found) => found ? found['fibery/name'] : null;

/**
 * Information about a project settings.
 * @typedef ProjectSettings
 * @type {object}
 * @property {string} id
 * @property {string} name
 * @property {string} partner
 * @property {string} team
 */

/**
 * Generates a response object with mapped values to correct properties
 * @param mappings
 * @returns {function(*): Object.<string, ProjectSettings>}
 */
const parseSpecialistsInvolvedQueryResult = (mappings) => (response) => {
  const parsed = {};

  for (let i = 0; i < mappings.length; i++) {
    const { specialists, timeEntries } = mappings[i].collections;
    const { project, fields } = mappings[i];
    const { success, result } = response[i];
    const [item] = result;

    if (!success) {
      console.error('command error: parseSpecialistsInvolvedQueryResult', item);
      continue;
    }

    if (!result.length) {
      continue;
    }

    if (result.length > 1) {
      console.error('more that 1 record: parseSpecialistsInvolvedQueryResult', item);
      continue;
    }

    parsed[project] = {
      specialist: item[fields.id],
      name: item[fields.name],
      collections: {
        specialists: specialists.find(s => s.startsWith(project)),
        timeEntries: timeEntries.find(s => s.startsWith(project)),
      },
    };

    if (fields.partner && item[fields.partner]) {
      parsed[project].partner = item[fields.partner]['fibery/id'];
    }

    if (fields.team && item[fields.team]) {
      parsed[project].team = item[fields.team]['fibery/id'];
    }
  }

  return parsed;
};

/**
 * Prepare request to Fibery to get information about all specialists available in projects
 * @param schemas
 * @returns {{query: [], parse: (function(*): Object<string, ProjectSettings>)}}
 */
export const prepareSpecialistsInvolvedQuery = (schemas) => {
  const specialistTypes = schemas['fibery/types'].filter(s => s['fibery/name'].toString().endsWith('Specialist') && !s['fibery/name'].toString().includes('_'));
  const specialistCollections = Array.from(new Set(specialistTypes.map(v => v['fibery/name'])));

  const timeEntriesTypes = schemas['fibery/types'].filter(s => s['fibery/name'].toString().endsWith('Time Entry') && s['fibery/name'].split('/').length === 2);
  const timeEntriesCollections = Array.from(new Set(timeEntriesTypes.map(v => v['fibery/name'])));

  const getPartner = (fields) => {
    const found = fields.find(v => v['fibery/name'].endsWith('Employee Partner Company'));
    return found && found['fibery/name'];
  };

  const getName = (fields) => {
    const found = fields.find(v => /\/name$/i.test(v['fibery/name']));
    return found && found['fibery/name'];
  };

  const getTeam = (fields) => {
    const found = fields.find(v => v['fibery/name'].includes('Team'));
    return found && found['fibery/name'];
  };

  const query = [];
  const mappings = [];
  for (const collection of specialistCollections) {
    const [projectName] = collection.split('/');

    if (projectName === 'Time Tracking App Template') {
      continue;
    }

    const type = schemas['fibery/types'].find(v => v['fibery/name'] === collection);
    const fields = type['fibery/fields'];

    const id = 'fibery/id';
    const name = getName(fields);
    const partner = getPartner(fields);
    const team = getTeam(fields);

    const select = [id, name];
    partner && select.push(Object.defineProperty({}, partner, { enumerable: true, value: ['fibery/id', 'enum/name'] }));
    team && select.push(Object.defineProperty({}, team, { enumerable: true, value: ['fibery/id', 'enum/name'] }));

    mappings.push({ project: projectName, fields: { id, name, partner, team }, collections: { specialists: specialistCollections, timeEntries: timeEntriesCollections } });

    query.push({
      command: 'fibery.entity/query',
      args: {
        query: {
          'q/from': collection,
          'q/select': select,
          'q/where': ['=', ['assignments/assignees', 'fibery/id'], '$my-id'],
          'q/limit': 'q/no-limit',
          'q/offset': 0,
        },
      },
    });
  }

  return { query, parse: parseSpecialistsInvolvedQueryResult(mappings) };
};

function parseGetTimeEntriesResult(data) {
  const response = [];

  for (const { success, result } of data) {
    if (!success) {
      console.error('parser error parseGetTimeEntriesResult', data);
    }

    response.push(...result);
  }

  return response.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
}

export function prepareGetTimeEntriesQuery({ schemas, from, to, projectsConfig }) {
  const types = schemas['fibery/types'].filter(s => s['fibery/name'].toString().endsWith('Time Entry') && s['fibery/name'].split('/').length === 2);
  const collections = Array.from(new Set(types.map(v => v['fibery/name'])));

  const allowedProjects = Object.keys(projectsConfig);
  const query = [];
  for (const collection of collections) {
    const [projectName] = collection.split('/');

    if (!allowedProjects.includes(projectName)) {
      continue;
    }

    if (projectName === 'Time Tracking App Template') {
      continue;
    }

    const type = schemas['fibery/types'].find(v => v['fibery/name'] === collection);
    const fields = type['fibery/fields'];

    const id = 'fibery/id';
    const description = getField(fields.find(v => /\/name$/i.test(v['fibery/name'])));
    const when = getField(fields.find(v => /when$/i.test(v['fibery/name'])));
    const time = getField(fields.find(v => /Time Spent$/i.test(v['fibery/name'])));
    const ticket = getField(fields.find(v => /Ticket Ref/i.test(v['fibery/name'])));
    const project = getField(fields.find(v => /Project Name/i.test(v['fibery/name'])));

    query.push({
      'command': 'fibery.entity/query',
      'args': {
        'query': {
          'q/from': collection,
          'q/select': { id, description, when, time, ticket, project },
          'q/where': [
            'and',
            ['=', ['fibery/created-by', 'fibery/id'], '$my-id'],
            ['>=', [when], '$from'],
            ['<=', [when], '$to'],
          ],
          'q/order-by': [
            [[when], 'q/desc'],
          ],
          'q/limit': 'q/no-limit',
          'q/offset': 0,
        },
        'params': {
          '$from': from,
          '$to': to,
        },
      },
    });
  }

  return { query, parse: parseGetTimeEntriesResult };
}

export function prepareSaveTimeEntryQueries({ schemas, id, when, time, ticket, description, settings, projectsConfig }) {
  const types = schemas['fibery/types'].filter(s => s['fibery/name'].toString().endsWith('Time Entry') && s['fibery/name'].split('/').length === 2);
  const collections = Array.from(new Set(types.map(v => v['fibery/name'])));

  for (const collection of collections) {
    const [projectName] = collection.split('/');

    if (settings.project !== projectName) {
      continue;
    }

    const type = schemas['fibery/types'].find(v => v['fibery/name'] === collection);
    const fields = type['fibery/fields'];

    const { collections } = projectsConfig[projectName];

    const idField = 'fibery/id';
    const descriptionField = getField(fields.find(v => /\/name$/i.test(v['fibery/name'])));
    const whenField = getField(fields.find(v => /when$/i.test(v['fibery/name'])));
    const timeField = getField(fields.find(v => /Time Spent$/i.test(v['fibery/name'])));
    const ticketField = getField(fields.find(v => /Ticket Ref/i.test(v['fibery/name'])));

    const baseEntity = {};
    ticket && ticketField && (baseEntity[ticketField] = ticket);
    time && timeField && (baseEntity[timeField] = timeToFloat(time));
    description && descriptionField && (baseEntity[descriptionField] = description);
    when && whenField && (baseEntity[whenField] = when);

    if (id) {
      const entity = Object.assign({}, baseEntity);
      idField && idField && (entity[idField] = id);

      return {
        query: [
          {
            command: 'fibery.entity/update',
            args: {
              type: collections.timeEntries,
              entity,
            },
          },
        ],
      };
    }

    const { team, specialist, partner, user } = settings;
    const meetingField = getField(fields.find(v => /Meeting Time$/i.test(v['fibery/name'])));
    const projectField = getField(fields.find(v => /Project Name/i.test(v['fibery/name'])));
    const teamField = getField(fields.find(v => /Team$/i.test(v['fibery/name'])));
    const specialistField = getField(fields.find(v => /Specialist Name$/i.test(v['fibery/name'])));
    const specialistNameField = getField(fields.find(v => /Specialist$/i.test(v['fibery/name'])));
    const partnerField = getField(fields.find(v => /Employee Partner Company$/i.test(v['fibery/name'])));

    time && meetingField && (baseEntity[meetingField] = ticket.toString().startsWith('meet') ? timeToFloat(time) : '0');
    projectName && projectField && (baseEntity[projectField] = projectName);
    team && teamField && (baseEntity[teamField] = { 'fibery/id': team });
    specialist && specialistField && (baseEntity[specialistField] = user.name);
    user && specialistNameField && (baseEntity[specialistNameField] = { 'fibery/id': specialist });
    partner && partnerField && (baseEntity[partnerField] = { 'fibery/id': partner });

    const createQuery = [
      {
        command: 'fibery.entity/create',
        args: {
          type: collections.timeEntries,
          entity: baseEntity,
        },
      },
    ];

    return { query: createQuery };
  }
}

export function prepareDeleteRecordQuery({ schemas, id, projectsConfig, project }) {
  const types = schemas['fibery/types'].filter(s => s['fibery/name'].toString().endsWith('Time Entry') && s['fibery/name'].split('/').length === 2);
  const collections = Array.from(new Set(types.map(v => v['fibery/name'])));

  for (const collection of collections) {
    const [projectName] = collection.split('/');

    if (project !== projectName) {
      continue;
    }

    const { collections } = projectsConfig[projectName];
    const idField = 'fibery/id';

    if (!id) {
      throw new Error('prepareDeleteRecordQuery can not find ID value');
    }

    const entity = {};
    idField && idField && (entity[idField] = id);

    return {
      query: [
        {
          'command': 'fibery.entity/delete',
          'args': {
            'type': collections.timeEntries,
            'entity': {
              'fibery/id': id,
            },
          },
        },
      ],
    };
  }

}