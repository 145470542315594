import { connect } from 'react-redux';
import component from './component';
import { userInterfaceModalWindowClose } from '../../store/actions/modal';
import { setTrackingFormDescription, setTrackingFormTicket, setTrackingFormTime, setTrackingFormWhen } from '../../store/actions/form';
import { getFormDescription, getFormId, getFormStatus, getFormTicket, getFormTime, getFormWhen } from '../../store/selectors/form';
import { getLoginToken } from '../../store/selectors/login';
import { fiberySaveRecord } from '../../store/commands/fibery';
import { getFilterRange, getFilterType } from '../../store/selectors/filter';
import { setSettingsProjectId } from '../../store/actions/settings';
import { getProjectsConfig, getSettingsProject, getSettingsSchemas, getSettingsSection } from '../../store/selectors/settings';

const mapStateToProps = (state) => ({
  when: getFormWhen(state),
  ticket: getFormTicket(state),
  description: getFormDescription(state),
  time: getFormTime(state),
  id: getFormId(state),
  settings: getSettingsSection(state),
  status: getFormStatus(state),

  projectsConfig: getProjectsConfig(state),
  schemas: getSettingsSchemas(state),
  project: getSettingsProject(state),
  token: getLoginToken(state),
  filterRange: getFilterRange(state),
  filterType: getFilterType(state),
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(userInterfaceModalWindowClose()),
  setTime: (time) => dispatch(setTrackingFormTime({ time })),
  setWhen: (when) => dispatch(setTrackingFormWhen({ when })),
  setTicket: (ticket) => dispatch(setTrackingFormTicket({ ticket })),
  setDescription: (description) => dispatch(setTrackingFormDescription({ description })),
  setProject: (project) => dispatch(setSettingsProjectId({ project })),
  saveRecord: fiberySaveRecord(dispatch),
});

const Container = connect(
    mapStateToProps,
    mapDispatchToProps,
)(component);

export default Container;
