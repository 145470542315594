import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';

const styles = theme => ({
  paper: {
    position: 'absolute',
    width: theme.spacing(80),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(5),
    outline: 'none',
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
})

class ModalComponent extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    clicker: PropTypes.node.isRequired,
  }

  render () {
    const {classes, children, clicker} = this.props

    const Clicker = React.cloneElement(clicker, {
      onClick: (e) => {
        this.props.actionOnClick && this.props.actionOnClick(e);
        this.props.openModal(e);
      },
    })

    return (
      <div>
        {Clicker}
        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={this.props.isOpen}
          onClose={this.props.closeModal}
        >
          <div className={classes.paper}>
            {children}
          </div>
        </Modal>
      </div>
    )
  }
}

// We need an intermediary variable for handling the recursive nesting.
export default withStyles(styles)(ModalComponent)
