import { types } from '../actions/snackbar';

const defaultState = {
  notifications: [],
};

const reductions = {
  [types.snackbarEnqueue]: (state, action) => ({
    ...state,
    notifications: [
      ...state.notifications,
      {
        key: action.key,
        ...action.notification,
      },
    ],
  }),

  [types.snackbarClose]: (state, action) => ({
    ...state,
    notifications: state.notifications.map(notification => (
        (action.dismissAll || notification.key === action.key)
            ? { ...notification, dismissed: true }
            : { ...notification }
    )),
  }),

  [types.snackbarRemove]: (state, action) => ({
    ...state,
    notifications: state.notifications.filter(
        notification => notification.key !== action.key,
    ),
  }),
};

export {
  defaultState as initialState,
  reductions,
};