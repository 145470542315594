import { connect } from 'react-redux';
import component from './component';
import { getFiberyRows, getFiberyRowsLastUpdate, getFiberyRowsStatus } from '../../store/selectors/fibery';
import { fiberyGetTimeEntriesList } from '../../store/commands';
import { userInterfaceModalWindowOpen } from '../../store/actions/modal';
import { getLoginToken } from '../../store/selectors/login';
import { getFilterRange, getFilterType } from '../../store/selectors/filter';
import { resetTrackingFormData, setTrackingFormData, setTrackingFormId, setTrackingFormWhen } from '../../store/actions/form';
import { fiberyDeleteRecord } from '../../store/commands/fibery';
import { getProjectsConfig, getSettingsSchemas } from '../../store/selectors/settings';
import { getCurrentDate } from '../../utils/date';

const mapStateToProps = (state) => ({
  rowsStatus: getFiberyRowsStatus(state),
  rows: getFiberyRows(state),
  token: getLoginToken(state),
  filterType: getFilterType(state),
  filterRange: getFilterRange(state),
  lastUpdate: getFiberyRowsLastUpdate(state),
  schemas: getSettingsSchemas(state),
  projectsConfig: getProjectsConfig(state),
});

const mapDispatchToProps = (dispatch, getState) => ({
  fiberyGetTimeEntriesList: fiberyGetTimeEntriesList(dispatch, getState),
  toggleTrackingForm: () => dispatch(userInterfaceModalWindowOpen()),
  resetTrackingFormData: () => dispatch(resetTrackingFormData()),
  copyRecord: (row) => () => {
    dispatch(setTrackingFormData(row));
    dispatch(setTrackingFormWhen({ when: getCurrentDate() }));
    dispatch(setTrackingFormId({ id: null }));
    dispatch(userInterfaceModalWindowOpen());
  },
  editRecord: (row) => () => {
    dispatch(setTrackingFormData(row));
    dispatch(userInterfaceModalWindowOpen());
  },
  deleteRecord: ({ token, filterRange, filterType, id, project, schemas, projectsConfig }) => fiberyDeleteRecord(dispatch)(
      { token, filterRange, filterType, id, project, schemas, projectsConfig },
  ),
});

const Container = connect(
    mapStateToProps,
    mapDispatchToProps,
)(component);

export default Container;
