import { filterSetFilterRange, filterSetFilterType } from '../actions/filter';

const current = (new Date()).toISOString();

export const filterTypeToLocalStorage = ({ type }) => (dispatch, getState) => () => {
  localStorage.setItem('filter_type', type || 'day');
};

export const filterTypeFromLocalStorage = (dispatch, getState) => () => {
  dispatch(filterSetFilterType({ type: localStorage.getItem('filter_type') || 'day' }));
};

export const filterRangeToLocalStorage = ({ range }) => (dispatch, getState) => () => {
  localStorage.setItem('filter_range', range || current);
};

export const filterRangeFromLocalStorage = (dispatch, getState) => () => {
  dispatch(filterSetFilterRange({ range: localStorage.getItem('filter_range') || current }));
};