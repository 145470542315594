import { types } from '../actions/login';

/**
 * Initial state of modal interface
 */
const initialState = {
  token: null,
};

const reductions = {
  [types.loginStoreTokenToLocalStorage]: (state, { token }) => {
    return {
      ...state,
      token,
    };
  },

  [types.loginTokenUpdate]: (state, { token }) => {
    return {
      ...state,
      token,
    };
  },
};

export {
  reductions,
  initialState,
};
