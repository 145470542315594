import { connect } from 'react-redux';
import component from './component';
import { getFilterRange, getFilterType } from '../../store/selectors/filter';
import { getFiberyRows } from '../../store/selectors/fibery';
import { filterSetFilterRange, filterSetFilterType } from '../../store/actions/filter';
import { filterRangeFromLocalStorage, filterRangeToLocalStorage, filterTypeFromLocalStorage, filterTypeToLocalStorage } from '../../store/commands/filter';

const mapStateToProps = (state) => ({
  type: getFilterType(state),
  range: getFilterRange(state),
  rows: getFiberyRows(state),
});

const mapDispatchToProps = (dispatch, getState) => {
  filterRangeFromLocalStorage(dispatch, getState)();
  filterTypeFromLocalStorage(dispatch, getState)();

  return {
    setType: (type) => dispatch(filterSetFilterType({ type })),
    setRange: (range) => dispatch(filterSetFilterRange({ range })),
    saveType: (type) => filterTypeToLocalStorage({ type })(dispatch, getState)(),
    saveRange: (range) => filterRangeToLocalStorage({ range })(dispatch, getState)(),
  };
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps,
)(component);

export default Container;
