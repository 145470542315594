const types = {
  setTrackingFormData: 'TrackingForm/FormData/Set',
  setTrackingFormTime: 'TrackingForm/TimeField/Set',
  setTrackingFormWhen: 'TrackingForm/WhenField/Set',
  setTrackingFormTicket: 'TrackingForm/TicketField/Set',
  setTrackingFormId: 'TrackingForm/RecordIdField/Set',
  setTrackingFormDescription: 'TrackingForm/DescriptionField/Set',
  resetTrackingFormData: 'TrackingForm/FormData/Reset',
  setFormStatus: 'TrackingForm/Status/Set',
};

const resetTrackingFormData = () => ({
  type: types.resetTrackingFormData,
});

const setTrackingFormData = ({ time, when, ticket, description, id }) => ({
  type: types.setTrackingFormData,
  payload: {
    id,
    time,
    when,
    ticket,
    description,
  },
});

const setTrackingFormTime = ({ time }) => ({
  type: types.setTrackingFormTime,
  payload: {
    time,
  },
});

const setTrackingFormWhen = ({ when }) => ({
  type: types.setTrackingFormWhen,
  payload: {
    when,
  },
});

const setTrackingFormTicket = ({ ticket }) => ({
  type: types.setTrackingFormTicket,
  payload: {
    ticket,
  },
});

const setTrackingFormDescription = ({ description }) => ({
  type: types.setTrackingFormDescription,
  payload: {
    description,
  },
});

const setTrackingFormId = ({ id }) => ({
  type: types.setTrackingFormId,
  payload: {
    id,
  },
});

const setFormStatus = ({ status }) => ({
  type: types.setFormStatus,
  payload: {
    status,
  },
});

export {
  types,

  setTrackingFormTime,
  setTrackingFormWhen,
  setTrackingFormTicket,
  setTrackingFormDescription,
  setTrackingFormId,
  setFormStatus,

  resetTrackingFormData,
  setTrackingFormData,
};
