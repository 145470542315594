import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/es/Button/Button';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});

class Component extends React.Component {
  static propTypes = {
    ticket: PropTypes.string,
    when: PropTypes.string,
    time: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string,

    closeModal: PropTypes.func.isRequired,
    setTime: PropTypes.func.isRequired,
    setWhen: PropTypes.func.isRequired,
    setTicket: PropTypes.func.isRequired,
    setDescription: PropTypes.func.isRequired,
    saveRecord: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
    this.handleWhenChange = this.handleWhenChange.bind(this);
    this.handleTicketChange = this.handleTicketChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleProjectChange = this.handleProjectChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleTimeChange(e) {
    this.props.setTime(e.target.value);
  }

  handleWhenChange(e) {
    this.props.setWhen(e.target.value);
  }

  handleTicketChange(e) {
    this.props.setTicket(e.target.value);
  }

  handleDescriptionChange(e) {
    this.props.setDescription(e.target.value);
  }

  handleProjectChange(e) {
    this.props.setProject(e.target.value);
  }

  handleSubmit(e) {
    this.props.saveRecord(this.props);
    e.preventDefault();
  }

  handleCancel(e) {
    this.props.closeModal();
    e.preventDefault();
  }

  renderProjectSelector() {
    const projectsList = Object.keys(this.props.projectsConfig);
    const projectsMenuItems = [];
    projectsList.forEach(p => projectsMenuItems.push(<MenuItem key={p} value={p}>{p}</MenuItem>));

    return (
        <FormControl variant="outlined" className={styles.formControl}>
          <InputLabel id="project">Project</InputLabel>
          <Select
              labelId="project"
              id="project"
              value={this.props.project}
              onChange={this.handleProjectChange}
              label="project"
          >{projectsMenuItems}</Select>
        </FormControl>
    );
  }

  render() {
    return (
        <React.Fragment>
          <form onSubmit={this.handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>

                <Grid container alignContent={'center'} justify={'space-between'}>
                  <TextField
                      id="time"
                      label="Duration"
                      type="time"
                      value={this.props.time}
                      onChange={this.handleTimeChange}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        step: 900, // 5 min
                      }}
                  />
                  {this.renderProjectSelector()}
                  <TextField
                      id="when"
                      label="Date"
                      type="date"
                      value={this.props.when}
                      onChange={this.handleWhenChange}
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                  />
                </Grid>

              </Grid>
              <Grid item xs={12}>
                <TextField
                    required
                    id="ticket"
                    name="ticket"
                    label="Ticket Number"
                    value={this.props.ticket}
                    onChange={this.handleTicketChange}
                    variant="outlined"
                    fullWidth
                    autoComplete="ticket"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                    id="description"
                    name="description"
                    label="Description"
                    fullWidth
                    autoComplete="description"
                    value={this.props.description}
                    onChange={this.handleDescriptionChange}
                    variant="outlined"
                    multiline
                    rowsMax={15}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container justify={'flex-end'}>
                  <Box mr={2}>
                    <Button variant="contained" name="cancel" onClick={this.handleCancel}>Cancel</Button>
                  </Box>
                  <Box mr={1}>
                    <Button color={'primary'} variant="contained" name="save" onClick={this.handleSubmit} disabled={this.props.status !== 'inactive'}>
                      {this.props.status !== 'inactive' ? <CircularProgress size={24}/> : 'Save'}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </React.Fragment>
    );
  }
}

export default withStyles(styles)(Component);
