const types = {
  loginStoreTokenToLocalStorage: 'Login/Storage/Set',
  loginTokenUpdate: 'Login/Token/Update',
};

const loginStoreTokenToLocalStorage = ({ token }) => ({
  type: types.loginStoreTokenToLocalStorage,
  payload: { token },
});

const loginTokenUpdate = ({ token }) => ({
  type: types.loginStoreTokenToLocalStorage,
  payload: { token },
});

export {
  types,

  loginStoreTokenToLocalStorage,
  loginTokenUpdate,
};
