import { fiberySetTrackingTableRows, setTrackingTableRowsLastUpdate, setTrackingTableRowsStatus } from '../actions/fibery';
import { userInterfaceModalWindowClose } from '../actions/modal';
import { setProjectsConfig, setSettingsProjectId, setSettingsSchemas } from '../actions/settings';
import { getRanger, toFiberyDateFormat } from '../../utils/filter';
import { prepareDeleteRecordQuery, prepareGetTimeEntriesQuery, prepareSaveTimeEntryQueries, prepareSpecialistsInvolvedQuery } from '../../utils/fibery';
import { setFormStatus } from '../actions/form';
import { enqueueSnackbar } from '../actions/snackbar';

export const prepareRequestOptions = ({ token, query }) => {
  const headers = new Headers();
  headers.append('Authorization', `Token ${token}`);
  headers.append('Content-Type', 'application/json');
  headers.append('X-Target-URL', 'https://geomotiv.fibery.io');

  return {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(query),
    redirect: 'follow',
  };
};

export const fiberyGetTimeEntriesList = (dispatch) => ({ token, filterType, filterRange, schemas, projectsConfig }) => {
  const range = getRanger(filterType)(filterRange);
  dispatch(setTrackingTableRowsStatus({ status: 'loading' }));
  const { query, parse } = prepareGetTimeEntriesQuery({ schemas, projectsConfig, from: toFiberyDateFormat(range.current.from), to: toFiberyDateFormat(range.current.to) });

  console.debug('HTTP Call: fiberyGetTimeEntriesList query', query);
  fetch('/api/commands', prepareRequestOptions({ token, query })).then(response => response.json()).then(result => {
    console.debug('HTTP Call: fiberyGetTimeEntriesList response', result);

    dispatch(fiberySetTrackingTableRows({ rows: parse(result) || [] }));
    dispatch(setTrackingTableRowsLastUpdate({ lastUpdate: new Date().toISOString() }));
    dispatch(setTrackingTableRowsStatus({ status: 'ready' }));
    dispatch(userInterfaceModalWindowClose());
  }).catch(error => console.log('error', error));
};

export const fiberyDeleteRecord = (dispatch) => ({ token, filterRange, filterType, id, projectsConfig, project, schemas }) => {
  const { query } = prepareDeleteRecordQuery({ schemas, projectsConfig, project, id });

  console.debug('HTTP Call: fiberyDeleteRecord query', query);
  fetch('/api/commands', prepareRequestOptions({ token, query })).then(response => response.json()).then(result => {
    console.debug('HTTP Call: fiberyDeleteRecord response', result);
    const [response] = result;
    if (response.success) {
      dispatch(enqueueSnackbar({ message: `${project} record deleted`, options: { variant: 'success' } }));
      fiberyGetTimeEntriesList(dispatch)({ token, filterRange, filterType, projectsConfig, schemas });
    } else {
      dispatch(enqueueSnackbar({ message: `Error on ${project} record delete`, options: { variant: 'error' } }));
      console.error('fiberyGetTimeEntriesList error', response);
    }
  }).catch(error => console.log('error', error));
};

export const fiberySaveRecord = (dispatch) => ({ token, filterRange, filterType, projectsConfig, schemas, id, when, time, ticket, description, settings }) => {
  const { query } = prepareSaveTimeEntryQueries({ schemas, id, when, time, ticket, description, settings, projectsConfig });

  dispatch(setFormStatus({ status: 'in-progress' }));

  console.debug('HTTP Call: fiberySaveRecord query', query);
  fetch('/api/commands', prepareRequestOptions({ token, query })).then(response => response.json()).then(result => {
    console.debug('HTTP Call: fiberySaveRecord response', result);

    dispatch(userInterfaceModalWindowClose());
    dispatch(setFormStatus({ status: 'inactive' }));

    const [response] = result;
    if (response.success) {
      fiberyGetTimeEntriesList(dispatch)({ token, filterRange, filterType, schemas, projectsConfig });

      dispatch(enqueueSnackbar({ message: `${settings.project} record saved`, options: { variant: 'success' } }));
    } else {
      dispatch(enqueueSnackbar({ message: `Error on saving ${settings.project} record`, options: { variant: 'error' } }));
      console.error('fiberyGetTimeEntriesList error', response);
    }
  }).catch(error => console.log('error', error));
};

export const loadInitData = (dispatch) => ({ token }) => {
  const query = [{ 'command': 'fibery.schema/query' }];

  console.debug('HTTP Call: loadInitData query', query);
  fetch('/api/commands', prepareRequestOptions({ token, query })).then(response => response.json()).then(result => {
    console.debug('HTTP Call: loadInitData result', result);
    const [response] = result;
    if (response.success) {
      dispatch(setSettingsSchemas({ schemas: response.result }));
      const { query: specialistQuery, parse } = prepareSpecialistsInvolvedQuery(response.result);

      console.debug('HTTP Call: loadInitData specialistQuery', query);
      fetch('/api/commands', prepareRequestOptions({ token, query: specialistQuery })).then(response => response.json()).then(result => {
        console.debug('HTTP Call: loadInitData specialistQuery result', result);
        const parsed = parse(result);
        dispatch(setProjectsConfig({ projectsConfig: parsed }));

        const projects = Object.keys(parsed);
        const [project] = projects;
        dispatch(setSettingsProjectId({ project }));
      }).catch(error => console.log('error', error));
    } else {
      console.error('command error: loadInitData', response);
    }
  }).catch(error => console.log('error', error));
};