import isNil from 'lodash/isNil'

const genericReducer = (initialState, reductionLookup) => (state, {type, payload}) => {
  const activeState = isNil(state) ? initialState : state
  const reducer = reductionLookup[type]
  return isNil(reducer) ? activeState : reducer(activeState, payload)
}

export {
  genericReducer,
}
