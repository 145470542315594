import { createSelector } from 'reselect';

const getSettingsSection = state => state.settings;

const getSettingsProject = createSelector(
    [getSettingsSection],
    (settings) => settings.project,
);

const getSettingsTeam = createSelector(
    [getSettingsSection],
    (settings) => settings.team,
);

const getSettingsAuthUserData = createSelector(
    [getSettingsSection],
    (settings) => settings.user,
);

const getSettingsSpecialist = createSelector(
    [getSettingsSection],
    (settings) => settings.specialist,
);

const getSettingsPartner = createSelector(
    [getSettingsSection],
    (settings) => settings.partner,
);

const getSettingsSchemas = createSelector(
    [getSettingsSection],
    (settings) => settings.schemas,
);

const getProjectsConfig = createSelector(
    [getSettingsSection],
    (settings) => settings.projectsConfig,
);

export {
  getSettingsSection,

  getSettingsProject,
  getSettingsTeam,
  getSettingsAuthUserData,
  getSettingsSpecialist,
  getSettingsPartner,
  getSettingsSchemas,
  getProjectsConfig,
};
