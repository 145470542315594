import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { withStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { floatToTime, getWeekNumber, rangeDay, rangeMonth, rangeWeek } from '../../utils/filter';

const styles = theme => ({});

class Component extends React.Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    range: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired,

    setType: PropTypes.func.isRequired,
    setRange: PropTypes.func.isRequired,
    saveType: PropTypes.func.isRequired,
    saveRange: PropTypes.func.isRequired,
  };

  handleUpdateType(type) {
    return () => {
      this.props.saveType(type);
      this.props.setType(type);
    };
  }

  renderRangeDay() {
    const range = rangeDay(new Date(this.props.range));
    const next = () => this.props.setRange(range.next.date);
    const prev = () => this.props.setRange(range.prev.date);

    return (
        <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
          <Button onClick={prev}>-</Button>
          <Button>
            <Typography variant={'h6'} align={'center'}>
              {(new Date(this.props.range)).toLocaleDateString()}
            </Typography>
          </Button>
          <Button onClick={next}>+</Button>
        </ButtonGroup>
    );
  }

  renderRangeWeek() {
    const range = rangeWeek(new Date(this.props.range));
    const next = () => this.props.setRange(range.next.date);
    const prev = () => this.props.setRange(range.prev.date);

    return (
        <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
          <Button onClick={prev}>-</Button>
          <Button>
            <Typography variant={'h6'} align={'center'}>
              {getWeekNumber(new Date(this.props.range))} / {(new Date(this.props.range)).getFullYear()}
            </Typography>
          </Button>
          <Button onClick={next}>+</Button>
        </ButtonGroup>
    );
  }

  renderRangeMonth() {
    const range = rangeMonth(new Date(this.props.range));
    const next = () => this.props.setRange(range.next.date);
    const prev = () => this.props.setRange(range.prev.date);

    return (
        <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
          <Button onClick={prev}>-</Button>
          <Button>
            <Typography variant={'h6'} align={'center'}>
              {(new Date(this.props.range)).toLocaleString('en-US', { month: 'short' })} / {(new Date(this.props.range)).getFullYear()}
            </Typography>
          </Button>
          <Button onClick={next}>+</Button>
        </ButtonGroup>
    );
  }

  renderRange() {
    switch (this.props.type) {
      case 'day':
        return this.renderRangeDay();
      case 'week':
        return this.renderRangeWeek();
      case 'month':
        return this.renderRangeMonth();
      default:
        return this.renderRangeDay();
    }
  }

  /**
   * Convert decimal value of heximicial.decimal to h:m:s format
   * @returns {string}
   */
  renderTimer() {
    const decimalTimeString = this.props.rows.map(row => Number.parseFloat(row.time)).reduce((prev, curr) => curr += prev, 0).toString();
    const { hours, minutes } = floatToTime(decimalTimeString);

    return `${hours}H ${minutes}M`;
  }

  render() {
    return (
        <Box py={3}>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item xs={5}>
              <Grid container justify={'flex-start'}>
                <ButtonGroup size="large" color="primary" aria-label="large outlined primary button group">
                  <Button variant={this.props.type === 'day' ? 'contained' : false} onClick={this.handleUpdateType('day')}>Day</Button>
                  <Button variant={this.props.type === 'week' ? 'contained' : false} onClick={this.handleUpdateType('week')}>Week</Button>
                  <Button variant={this.props.type === 'month' ? 'contained' : false} onClick={this.handleUpdateType('month')}>Month</Button>
                </ButtonGroup>
              </Grid>
            </Grid>

            <Grid item xs={2}>
              <Typography variant={'h5'} align={'center'}>
                {this.renderTimer()}
              </Typography>
            </Grid>

            <Grid item xs={5}>
              <Grid container justify={'flex-end'}>
                {this.renderRange()}
              </Grid>
            </Grid>
          </Grid>
        </Box>
    );
  }
}

export default withStyles(styles)(Component);