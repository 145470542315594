/**
 * Get week number of provided date
 * @see https://stackoverflow.com/questions/6117814/get-week-of-year-in-javascript-like-in-php
 * @param date
 * @returns {number}
 */
const getWeekNumber = function(date) {
  const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
};

/**
 * Prepare fibery formatted date value for When field
 * @param {(string|Date)} date ISO formatted string
 * @returns {string}
 */
const toFiberyDateFormat = (date) => {
  const [month, day, year] = (new Date(date)).toLocaleDateString('en-US').split('/');
  return [year, month, day].join('-');
};

/**
 * @see https://stackoverflow.com/questions/3274908/how-to-calculate-date-range-for-week-and-month-in-javascript
 * @param date
 * @returns {{from: Date, to: Date}}
 */
function rangeWeekCalculate(date) {
  if (!date) date = new Date().getTime();
  let dt = new Date(date);
  dt = new Date(dt.getFullYear(), dt.getMonth(), dt.getDate());
  dt = new Date(dt.getTime() - (dt.getDay() > 0 ? (dt.getDay() - 1) * 1000 * 60 * 60 * 24 : 6 * 1000 * 60 * 60 * 24));
  return { from: dt, to: new Date(dt.getTime() + 1000 * 60 * 60 * 24 * 7 - 1) };
}

/**
 * Calculate month range
 * @param {(string|Date)} date
 * @returns {{from: Date, to: Date}}
 */
function rangeMonthCalculate(date) {
  function getMonthFrom(date) {
    const from = new Date(date);
    from.setHours(0, 0, 0, 0);
    from.setDate(1);
    return from;
  }

  const from = getMonthFrom(date);

  const nextMonth = new Date(date);
  nextMonth.setMonth(nextMonth.getMonth() + 1);
  const to = new Date(getMonthFrom(nextMonth).getTime() - 1);

  return {
    from,
    to,
  };
}

/**
 * Calculate day range
 * @param {(string|Date)} date
 * @returns {{from: Date, to: Date}}
 */
function rangeDayCalculate(date) {
  const from = new Date(date);
  from.setHours(0, 0, 0, 0);

  const to = new Date(date);
  to.setHours(23, 59, 59, 999);

  return {
    from,
    to,
  };
}

/**
 * Get range function based on type
 * @param {("day"|"week"|"month")} type
 * @returns {function(Date): {next: {date: string, from: Date, to: Date}, current: {date: string, from: Date, to: Date}, prev: {date: string, from: Date, to: Date}}}
 */
function getRanger(type) {
  switch (type) {
    case 'month':
      return rangeMonth;
    case 'week':
      return rangeWeek;
    case 'day':
    default:
      return rangeDay;
  }
}

/**
 * Prepare range information by month based on provided date
 * @param {Date} range
 * @returns {{next: {date: string, from: Date, to: Date}, current: {date: string, from: Date, to: Date}, prev: {date: string, from: Date, to: Date}}}
 */
function rangeMonth(range) {
  const current = new Date(range);

  const prev = new Date(current);
  prev.setMonth(prev.getMonth() - 1);

  const next = new Date(current);
  next.setMonth(next.getMonth() + 1);

  return {
    prev: {
      date: prev.toISOString(),
      ...rangeMonthCalculate(prev),
    },
    current: {
      date: current.toISOString(),
      ...rangeMonthCalculate(current),
    },
    next: {
      date: next.toISOString(),
      ...rangeMonthCalculate(next),
    },
  };
}

/**
 * Prepare range information by week based on provided date
 * @param {Date} range
 * @returns {{next: {date: string, from: Date, to: Date}, current: {date: string, from: Date, to: Date}, prev: {date: string, from: Date, to: Date}}}
 */
function rangeWeek(range) {
  const current = new Date(range);

  const prev = new Date(current);
  prev.setDate(prev.getDate() - 7);

  const next = new Date(current);
  next.setDate(next.getDate() + 7);

  return {
    prev: {
      date: prev.toISOString(),
      ...rangeWeekCalculate(prev),
    },
    current: {
      date: current.toISOString(),
      ...rangeWeekCalculate(current),
    },
    next: {
      date: next.toISOString(),
      ...rangeWeekCalculate(next),
    },
  };
}

/**
 * Prepare range information by week based on provided date
 * @param {Date} range
 * @returns {{next: {date: string, from: Date, to: Date}, current: {date: string, from: Date, to: Date}, prev: {date: string, from: Date, to: Date}}}
 */
function rangeDay(range) {
  const current = new Date(range);

  const prev = new Date(current);
  prev.setDate(prev.getDate() - 1);

  const next = new Date(current);
  next.setDate(next.getDate() + 1);

  return {
    prev: {
      date: prev,
      ...rangeDayCalculate(prev),
    },
    current: {
      date: current,
      ...rangeDayCalculate(current),

    },
    next: {
      date: next,
      ...rangeDayCalculate(next),
    },
  };
}

/**
 * Convert float values to HH:MM:SS values
 * @param {(string|number)} floatTime
 * @returns {{hours: number, seconds: number, minutes: number}}
 */
function floatToTime(floatTime) {
  let decimalTime = Number.parseFloat(floatTime);
  decimalTime = decimalTime * 60 * 60;

  let hours = Math.floor((decimalTime / (60 * 60)));
  decimalTime = decimalTime - (hours * 60 * 60);

  let minutes = Math.floor((decimalTime / 60));
  decimalTime = decimalTime - (minutes * 60);

  let seconds = Math.round(decimalTime);

  if (hours < 10) {
    hours = '0' + hours;
  }

  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  return {
    hours,
    minutes,
    seconds,
  };
}

/**
 * Convert time in format HH:MM:SS to float value
 * @param {string} time
 * @returns {number}
 */
function timeToFloat(time) {
  const [hours, minutes = 0, seconds = 0] = time.split(':').map(value => Number.parseInt(value));
  const value = hours + (minutes / 60) + (seconds / 3600);

  if (value <= 0 || Number.isNaN(hours) || Number.isNaN(minutes) || Number.isNaN(seconds)) {
    throw new Error(`Wrong time format for value ${time}`);
  }

  return value;
}

export {
  rangeDay,
  rangeWeek,
  rangeMonth,
  getWeekNumber,
  getRanger,
  toFiberyDateFormat,
  floatToTime,
  timeToFloat,
};