export const types = {
  snackbarEnqueue: 'UserInterface/Snackbar/Enqueue',
  snackbarClose: 'UserInterface/Snackbar/Close',
  snackbarRemove: 'UserInterface/Snackbar/Remove',
};

const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;

  return {
    type: types.snackbarEnqueue,
    payload: {
      notification: {
        ...notification,
        key: key || new Date().getTime() + Math.random(),
      },
    },
  };
};

const closeSnackbar = key => ({
  type: types.snackbarClose,
  payload: {
    dismissAll: !key, // dismiss all if no key has been defined
    key,
  },
});

const removeSnackbar = key => ({
  type: types.snackbarRemove,
  payload: {
    key,
  },
});

export {
  enqueueSnackbar,
  closeSnackbar,
  removeSnackbar,
};