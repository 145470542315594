const types = {
  filterSetFilterType: 'Filter/Type/Set',
  filterSetRange: 'Filter/Range/Set',
};

const filterSetFilterType = ({ type }) => ({
  type: types.filterSetFilterType,
  payload: {
    type,
  },
});

const filterSetFilterRange = ({ range }) => ({
  type: types.filterSetRange,
  payload: {
    range,
  },
});

export {
  types,

  filterSetFilterType,
  filterSetFilterRange,
};
