import { connect } from 'react-redux';
import component from './component';

const mapDispatchToProps = (dispatch, getState) => ({});

const Container = connect(
    undefined,
    mapDispatchToProps,
)(component);

export default Container;
