import { types } from '../actions/modal'

/**
 * Initial state of modal interface
 */
const initialState = {
  isOpen: false,
}

const reductions = {
  [types.closeModal]: (state) => {
    return {
      ...state,
      isOpen: false,
    }
  },

  [types.openModal]: (state) => {
    return {
      ...state,
      isOpen: true,
    }
  },

  [types.toggleModal]: (state) => {
    return {
      ...state,
      isOpen: !state.isOpen,
    }
  },
}

export {
  reductions,
  initialState,
}
