import { types } from '../actions/form';
import { floatToTime } from '../../utils/filter';
import { getCurrentDate } from '../../utils/date';

/**
 * Initial state of modal interface
 */
const initialState = {
  id: null,
  time: '01:00',
  when: getCurrentDate(),
  ticket: '',
  description: '',
  status: 'inactive',
};

const reductions = {
  [types.resetTrackingFormData]: (state) => {
    return {
      ...state,
      ...initialState,
      when: getCurrentDate(),
    };
  },

  [types.setTrackingFormData]: (state, { time, when, ticket, description, id }) => {
    const { hours, minutes } = floatToTime(time);
    return {
      ...state,
      id,
      time: [hours, minutes].join(':'),
      when,
      ticket,
      description,
    };
  },

  [types.setTrackingFormDescription]: (state, { description }) => {
    return {
      ...state,
      description,
    };
  },

  [types.setTrackingFormTicket]: (state, { ticket }) => {
    return {
      ...state,
      ticket,
    };
  },

  [types.setTrackingFormTime]: (state, { time }) => {
    return {
      ...state,
      time,
    };
  },

  [types.setTrackingFormWhen]: (state, { when }) => {
    return {
      ...state,
      when,
    };
  },

  [types.setTrackingFormId]: (state, { id }) => {
    return {
      ...state,
      id,
    };
  },

  [types.setFormStatus]: (state, { status }) => {
    return {
      ...state,
      status,
    };
  },
};

export {
  reductions,
  initialState,
};
