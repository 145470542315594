import { loginStoreTokenToLocalStorage as actionLoginStoreTokenToLocalStorage } from '../actions/login';

export const loginStoreTokenToLocalStorage = (dispatch, getState) => ({ token }) => {
  localStorage.setItem('access_token', token);
  dispatch(actionLoginStoreTokenToLocalStorage({ token }));
};

export const loginReadTokenFromLocalStorage = (dispatch, getState) => () => {
  dispatch(actionLoginStoreTokenToLocalStorage({ token: localStorage.getItem('access_token') }));
};
