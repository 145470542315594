import { connect } from 'react-redux';
import component from './component';
import { loginStoreTokenToLocalStorage } from '../../store/commands/login';
import { getLoginToken } from '../../store/selectors/login';

const mapStateToProps = (state) => ({
  token: getLoginToken(state),
});

const mapDispatchToProps = (dispatch, getState) => {
  return {
    loginStoreTokenToLocalStorage: loginStoreTokenToLocalStorage(dispatch, getState),
  };
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps,
)(component);

export default Container;
