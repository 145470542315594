import { createSelector } from 'reselect'

const getModalWindow = state => state.modal

const getModalWindowIsOpen = createSelector(
  [getModalWindow],
  (modal) => modal.isOpen,
)

export {
  getModalWindow,
  getModalWindowIsOpen,
}
