import { types } from '../actions/settings';

/**
 * Initial state of modal interface
 */
const initialState = {
  team: '',
  specialist: '',
  partner: '',
  project: '',
  projectsConfig: null,
  schemas: null,
};

const reductions = {
  [types.setSettingsProjectId]: (state, { project }) => {
    const { team, specialist, partner } = state.projectsConfig[project];

    return {
      ...state,
      project,
      team,
      specialist,
      partner,
    };
  },

  [types.setSettingsSpecialistId]: (state, { specialist }) => {
    return {
      ...state,
      specialist,
    };
  },

  [types.setSettingsTeamId]: (state, { team }) => {
    return {
      ...state,
      team,
    };
  },

  [types.setSettingsPartnerId]: (state, { partner }) => {
    return {
      ...state,
      partner,
    };
  },

  [types.setAuthUserData]: (state, { user }) => {
    return {
      ...state,
      user,
    };
  },

  [types.setSettingsSchemas]: (state, { schemas }) => {
    return {
      ...state,
      schemas,
    };
  },

  [types.setProjectsConfig]: (state, { projectsConfig }) => {
    return {
      ...state,
      projectsConfig,
    };
  },
};

export {
  reductions,
  initialState,
};
