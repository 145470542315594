import { createSelector } from 'reselect';

const getLoginSection = state => state.login;

const getLoginToken = createSelector(
    [getLoginSection],
    (login) => login.token,
);

export {
  getLoginSection,
  getLoginToken,
};
