import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TrackingTable from '../../components/trackingTable';
import Notifier from '../../components/notifier';

export default function CollapsibleTable(props) {
  return (
      <Grid container direction="column" justify="center" alignItems="center">
        <Notifier/>
        <Box margin={2}>
          <Grid container item xs={12} justify="center" alignItems="center" direction={'row'} alignContent={'center'}>
            <Typography variant="h3" component="div">Time Tracking</Typography>
          </Grid>
        </Box>
        <TrackingTable/>
      </Grid>
  );
}