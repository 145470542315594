import React from 'react';
import { withSnackbar } from 'notistack';

class Component extends React.Component {
  displayed = [];

  storeDisplayed(id) {
    this.displayed = [...this.displayed, id];
  };

  removeDisplayed(id) {
    this.displayed = [...this.displayed.filter(key => id !== key)];
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const notifications = this.props.snackbar;
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        this.props.closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (this.displayed.includes(key)) return;

      // display snackbar using notistack
      this.props.enqueueSnackbar(message, {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          this.props.removeSnackbar(myKey);
          this.removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      this.storeDisplayed(key);
    });
  }

  render() {
    return (
        <React.Fragment/>
    );
  }
}

export default withSnackbar(Component);
