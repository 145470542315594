import {connect} from 'react-redux'
import component from './component'
import {
  userInterfaceModalWindowClose,
  userInterfaceModalWindowOpen,
  userInterfaceModalWindowToggle
} from '../../store/actions/modal'
import {getModalWindowIsOpen} from '../../store/selectors/modal'

const mapStateToProps = (state) => ({
  isOpen: getModalWindowIsOpen(state),
})

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(userInterfaceModalWindowClose()),
  openModal: () => dispatch(userInterfaceModalWindowOpen()),
  toggleModal: () => dispatch(userInterfaceModalWindowToggle()),
})

const Container = connect(
    mapStateToProps,
    mapDispatchToProps,
)(component)

export default Container
