const types = {
  closeModal: 'UserInterface/ModalWindow/Close',
  openModal: 'UserInterface/ModalWindow/Open',
  toggleModal: 'UserInterface/ModalWindow/Toggle',
}

const userInterfaceModalWindowClose = () => ({
  type: types.closeModal,
})

const userInterfaceModalWindowOpen = () => ({
  type: types.openModal,
})

const userInterfaceModalWindowToggle = () => ({
  type: types.toggleModal,
})

export {
  types,

  userInterfaceModalWindowClose,
  userInterfaceModalWindowOpen,
  userInterfaceModalWindowToggle,
}
