import { createSelector } from 'reselect';

const getFilterSection = state => state.filter;

const getFilterType = createSelector(
    [getFilterSection],
    (filter) => filter.type,
);

const getFilterRange = createSelector(
    [getFilterSection],
    (filter) => new Date(filter.range).toISOString(),
);

export {
  getFilterType,
  getFilterRange,
};
