import { createSelector } from 'reselect';

const getFormSection = state => state.form;

const getFormData = createSelector(
    [getFormSection],
    (form) => form,
);

const getFormDescription = createSelector(
    [getFormSection],
    (form) => form.description,
);

const getFormTime = createSelector(
    [getFormSection],
    (form) => form.time,
);

const getFormWhen = createSelector(
    [getFormSection],
    (form) => form.when,
);

const getFormTicket = createSelector(
    [getFormSection],
    (form) => form.ticket,
);

const getFormId = createSelector(
    [getFormSection],
    (form) => form.id,
);

const getFormStatus = createSelector(
    [getFormSection],
    (form) => form.status,
);

export {
  getFormData,
  getFormDescription,
  getFormTicket,
  getFormTime,
  getFormWhen,
  getFormId,
  getFormStatus,
};
