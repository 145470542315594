const types = {
  setTrackingTableRows: 'Fibery/TrackingTableRows/Set',
  setTrackingTableRowsLastUpdate: 'Fibery/TrackingTableRowsLastUpdate/Set',
  setTrackingTableRowsStatus: 'Fibery/TrackingTableRowsStatus/Set',
};

const fiberySetTrackingTableRows = ({ rows }) => ({
  type: types.setTrackingTableRows,
  payload: {
    rows,
  },
});

const setTrackingTableRowsLastUpdate = ({ lastUpdate }) => ({
  type: types.setTrackingTableRowsLastUpdate,
  payload: {
    lastUpdate: new Date(lastUpdate).toISOString(),
  },
});

const setTrackingTableRowsStatus = ({ status }) => ({
  type: types.setTrackingTableRowsStatus,
  payload: {
    status,
  },
});

export {
  types,

  fiberySetTrackingTableRows,
  setTrackingTableRowsLastUpdate,
  setTrackingTableRowsStatus,
};
