import { types } from '../actions/filter';

const initialState = {
  type: 'month',
  range: (new Date()).toISOString(),
};

const reductions = {
  [types.filterSetFilterType]: (state, { type }) => {
    return {
      ...state,
      type,
    };
  },

  [types.filterSetRange]: (state, { range }) => {
    return {
      ...state,
      range,
    };
  },
};

export {
  reductions,
  initialState,
};
