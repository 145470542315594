import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import store from './store';
import { loginReadTokenFromLocalStorage } from './store/commands/login';
import { settingsLoadInitData } from './store/commands/settings';
import { loadInitData } from './store/commands/fibery';
import { getLoginToken } from './store/selectors/login';
import LoginComponent from './pages/login';
import TrackingComponent from './pages/tracking';

const loadFromLocalStorage = dispatch => {
  loginReadTokenFromLocalStorage(dispatch)();
};

// each time when we getting new token we should reload init data for token
let currentToken;
store.subscribe(() => {
  let prevToken = currentToken;
  currentToken = getLoginToken(store.getState());

  if (prevToken !== currentToken) {
    loadInitData(store.dispatch)({ token: currentToken });
    settingsLoadInitData(store.dispatch)({ token: currentToken });
  }
});

class App extends Component {
  componentDidMount() {
    document.title = 'Geomotiv Time Tracking';
  }

  render() {
    loadFromLocalStorage(store.dispatch);

    return (
        <Provider store={store}>
          <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={2500}>
            <Router>
              <Switch>
                <Route exact path="/" component={LoginComponent}/>
                <Route exact path="/tracking" component={TrackingComponent}/>
              </Switch>
            </Router>
          </SnackbarProvider>
        </Provider>
    );
  }
}

export default App;
