import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing(1) * 3,
    marginRight: theme.spacing(1) * 3,
    [theme.breakpoints.up(400 + theme.spacing(1) * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(1) * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(1) * 2}px ${theme.spacing(1) * 3}px ${theme.spacing(1) * 3}px`,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginTop: theme.spacing(1) * 3,
  },
});

class LoginPage extends React.Component {
  static propTypes = {
    loginStoreTokenToLocalStorage: PropTypes.func.isRequired,
    token: PropTypes.string,
  };

  constructor(props) {
    super(props);
    const { classes } = props;
    this.state = { token: '' };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.classes = classes;
  }

  componentDidMount() {
    if (this.props.token) {
      this.props.history.push('/tracking');
    }
  }

  componentDidUpdate() {
    if (this.props.token) {
      this.props.history.push('/tracking');
    }
  }

  handleSubmit(event) {
    this.props.loginStoreTokenToLocalStorage({ token: this.state.token });
    this.props.history.push('/tracking');
    event.preventDefault();
  }

  handleChange(event) {
    this.setState({ token: event.target.value });
  }

  render() {
    return (
        <main className={this.classes.main}>
          <CssBaseline/>
          <Paper className={this.classes.paper}>
            <Avatar className={this.classes.avatar}>
              <LockIcon/>
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form onSubmit={this.handleSubmit} className={this.classes.form}>
              <FormControl margin="normal" required fullWidth>
                <InputLabel htmlFor="token">Fibery Access Token</InputLabel>
                <Input id="token" name="token" autoComplete="token" value={this.state.value} onChange={this.handleChange} autoFocus/>
              </FormControl>
              <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={this.classes.submit}
                  onClick={this.handleSubmit}
              >
                Sign in
              </Button>
            </form>
          </Paper>
        </main>
    );
  }
}

export default withStyles(styles)(LoginPage);
