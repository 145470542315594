import { combineReducers } from 'redux';
import { genericReducer } from '../../utils/store';
import { initialState as modalInitialState, reductions as modalReductions } from './modal';
import { initialState as fiberyInitialState, reductions as fiberyReductions } from './fibery';
import { initialState as loginInitialState, reductions as loginReductions } from './login';
import { initialState as filterInitialState, reductions as filterReductions } from './filter';
import { initialState as formInitialState, reductions as formReductions } from './form';
import { initialState as settingsInitialState, reductions as settingsReductions } from './settings';
import { initialState as snackbarInitialState, reductions as snackbarReductions } from './snackbar';

const reducers = combineReducers({
  modal: genericReducer(modalInitialState, modalReductions),
  fibery: genericReducer(fiberyInitialState, fiberyReductions),
  login: genericReducer(loginInitialState, loginReductions),
  filter: genericReducer(filterInitialState, filterReductions),
  form: genericReducer(formInitialState, formReductions),
  settings: genericReducer(settingsInitialState, settingsReductions),
  snackbar: genericReducer(snackbarInitialState, snackbarReductions),
});

export default reducers;
