import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { default as reducers } from './reducers'

const middlewares = [
  thunk,
]

/**
 * Compose enhancers
 */
const composeEnhancers = (window && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  : compose

/**
 * Create Redux Store
 */
export default createStore(reducers, composeEnhancers(
  applyMiddleware(...middlewares),
))
