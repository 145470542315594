import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';
import CopyIcon from '@material-ui/icons/FileCopy';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ModalWindow from '../modal';
import TrackingForm from '../trackingForm';
import TrackingFilters from '../trackingTableFilters';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { floatToTime } from '../../utils/filter';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

function CircularIndeterminate() {
  const classes = makeStyles((theme) => ({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2),
      },
    },
  }));

  return (
      <TableRow>
        <TableCell colSpan={5}>
          <Box my={5} className={classes.root}>
            <Grid container justify={'center'} alignContent={'center'}>
              <CircularProgress color="primary"/>
            </Grid>
          </Box>
        </TableCell>
      </TableRow>
  );
}

function NoContent() {
  return (
      <TableRow>
        <TableCell colSpan={5}>
          <Box my={5}>
            <Grid container justify={'center'} alignContent={'center'}>
              <Typography variant={'h5'}>No records found</Typography>
            </Grid>
          </Box>
        </TableCell>
      </TableRow>
  );
}

const styles = () => ({});

/**
 * ButtonGroup injects some properties into its children (using cloneElement) assuming they implement the fullWidth prop.
 * Button does this but not IconButton which forwards excess props to the underlying DOM component. However, <div fullWidth>
 * is not implemented by react-dom and it assumes that fullwidth is a non-standard HTML attribute in which case
 * it should be lower cased.
 *
 * The solution is to use a context instead of cloneElement so that children have to opt-in to button group props instead of having to opt-out.
 *
 * @see https://github.com/mui-org/material-ui/issues/17226#issuecomment-672980848
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
function ButtonGroupIconButton(props) {
  // intercept props only implemented by `Button`
  const { disableElevation, fullWidth, variant, ...iconButtonProps } = props;
  return <IconButton {...iconButtonProps} />;
}

class Row extends React.Component {
  static propTypes = {
    editRecord: PropTypes.func.isRequired,
    copyRecord: PropTypes.func.isRequired,
    deleteRecord: PropTypes.func.isRequired,

    prevWhen: PropTypes.string,
    filterType: PropTypes.string,

    row: PropTypes.shape({
      'ticket': PropTypes.string,
      'project': PropTypes.string,
      'person': PropTypes.string,
      'time': PropTypes.string,
      'when': PropTypes.string,
      'description': PropTypes.string,
      'updatedAt': PropTypes.string,
      'createdAt': PropTypes.string,
      'id': PropTypes.string,
    }).isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
    };

    this.handleToggleDialog = this.handleToggleDialog.bind(this);
    this.handleDialogClose = this.handleDialogClose.bind(this);
    this.handleDialogSubmit = this.handleDialogSubmit.bind(this);
  }

  renderDelimiter(prevWhen, when) {
    if (this.props.filterType === 'day') {
      return;
    }

    if (prevWhen === when) {
      return;
    }

    return (
        <React.Fragment>
          <TableRow style={{ border: 'none' }}>
            <TableCell colSpan={5} style={{ border: 'none' }}/>
          </TableRow>
          <TableRow>
            <TableCell colSpan={5}>
              <Grid container>
                <Typography variant={'h6'}>{new Date(when).toLocaleDateString()}</Typography>
              </Grid>
            </TableCell>
          </TableRow>
        </React.Fragment>
    );
  }

  handleToggleDialog() {
    this.setState({ dialogOpen: !this.state.dialogOpen });
  }

  handleDialogClose() {
    this.setState({ dialogOpen: false });
  }

  handleDialogSubmit() {
    this.setState({ deleteInProgress: true });
    this.props.deleteRecord();
  }

  render() {
    const { row } = this.props;

    return (
        <React.Fragment>
          {this.renderDelimiter(this.props.prevWhen, row.when)}
          <TableRow>
            <TableCell>{row.description}</TableCell>
            <TableCell><span style={{ whiteSpace: 'nowrap' }}>{row.ticket}</span></TableCell>
            <TableCell><span style={{ whiteSpace: 'nowrap' }}>{[floatToTime(row.time).hours, floatToTime(row.time).minutes].join(':')}</span></TableCell>

            <TableCell align="right">
              <ButtonGroup size="large">
                <ButtonGroupIconButton size="medium" onClick={this.props.copyRecord}>
                  <CopyIcon/>
                </ButtonGroupIconButton>
                <ButtonGroupIconButton size="medium" onClick={this.props.editRecord}>
                  <EditIcon/>
                </ButtonGroupIconButton>

                <ButtonGroupIconButton size="medium" onClick={this.handleToggleDialog}>
                  <Dialog
                      open={this.state.dialogOpen}
                      onClose={this.handleDialogClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">{'Delete confirmation'}</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">Do you really want to delete this record?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleDialogClose} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={this.handleDialogSubmit} color="secondary" autoFocus>
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <DeleteIcon/>
                </ButtonGroupIconButton>

              </ButtonGroup>
            </TableCell>

          </TableRow>
        </React.Fragment>
    );
  }
}

class TrackingTableComponent extends React.Component {
  static propTypes = {
    resetTrackingFormData: PropTypes.func.isRequired,
    fiberyGetTimeEntriesList: PropTypes.func.isRequired,
    editRecord: PropTypes.func.isRequired,
    copyRecord: PropTypes.func.isRequired,
    deleteRecord: PropTypes.func.isRequired,

    rowsStatus: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired,
    token: PropTypes.string,
    filterType: PropTypes.string,
    filterRange: PropTypes.string,
    lastUpdate: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.handleNewRecordAction = this.handleNewRecordAction.bind(this);
  }

  isSchemasExists;
  isProjectsConfigExists;

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const isSchemasExists = !!nextProps.schemas || !!this.props.schemas;
    const isProjectsConfigExists = !!nextProps.projectsConfig || !!this.props.projectsConfig;

    if ((
        nextProps.token !== this.props.token
        || nextProps.filterRange !== this.props.filterRange
        || nextProps.filterType !== this.props.filterType
        || this.isSchemasExists !== isSchemasExists
    ) && isSchemasExists && isProjectsConfigExists) {
      this.isSchemasExists = true;
      this.isProjectsConfigExists = true;

      this.props.token && this.props.fiberyGetTimeEntriesList({
        token: nextProps.token || this.props.token,
        filterType: nextProps.filterType || this.props.filterType,
        filterRange: nextProps.filterRange || this.props.filterRange,
        schemas: nextProps.schemas || this.props.schemas,
        projectsConfig: nextProps.projectsConfig || this.props.projectsConfig,
      });

      return true;
    }

    if (nextProps.lastUpdate !== this.props.lastUpdate) {
      return true;
    }

    if (nextProps.rowsStatus !== this.props.rowsStatus) {
      return true;
    }

    return false;
  }

  handleNewRecordAction() {
    this.props.resetTrackingFormData();
  }

  getClicker() {
    return (<IconButton aria-label="expand row" size="small">
      <AddIcon/>
    </IconButton>);
  }

  getRow(rows) {
    if (this.props.rowsStatus === 'loading') {
      return <CircularIndeterminate/>;
    }

    if (!rows.length) {
      return <NoContent/>;
    }

    let prevWhen;
    return rows.map((row) => {
      const copyRecord = this.props.copyRecord(row);
      const editRecord = this.props.editRecord(row);
      const deleteRecord = () => this.props.deleteRecord({ ...this.props, id: row.id, project: row.project });

      const element = (
          <Row
              key={row.id}
              row={row}
              copyRecord={copyRecord}
              editRecord={editRecord}
              prevWhen={prevWhen}
              filterType={this.props.filterType}
              deleteRecord={deleteRecord}
          />
      );

      prevWhen = row.when;

      return element;
    });
  }

  render() {
    const { rows } = this.props;

    return (
        <Grid container item xs={12} md={10} lg={8} xl={6}>
          <Grid item xs={12}>
            <TrackingFilters/>
          </Grid>
          <Grid item xs={12}>
            <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Description</strong></TableCell>
                    <TableCell><strong>Ticket</strong></TableCell>
                    <TableCell><strong>Time</strong></TableCell>
                    <TableCell align={'right'}>
                      <ModalWindow clicker={this.getClicker()} actionOnClick={this.handleNewRecordAction}>
                        <TrackingForm/>
                      </ModalWindow>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.getRow(rows)}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
    );
  }
}

// We need an intermediary variable for handling the recursive nesting.
export default withStyles(styles)(TrackingTableComponent);