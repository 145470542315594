import { setAuthUserData } from '../actions/settings';
import { prepareRequestOptions } from './fibery';

export const settingsLoadInitData = (dispatch) => ({ token }) => {
  const query = [
    // loading logged in user information
    {
      'command': 'fibery.entity/query',
      'args': {
        'query': {
          'q/from': 'fibery/user',
          'q/select': {
            'id': 'fibery/id',
            'email': 'user/email',
            'name': 'user/name',
            'avatar': {
              'q/from': [
                'avatar/avatars',
              ],
              'q/select': {
                'id': 'fibery/id',
                'name': 'fibery/name',
                'content-type': 'fibery/content-type',
                'secret': 'fibery/secret',
              },
              'q/limit': 'q/no-limit',
            },
          },
          'q/where': ['=', ['fibery/id'], '$my-id'],
          'q/limit': 1,
        },
      },
    },
  ];

  console.debug('HTTP Call: settingsLoadInitData query', query);
  fetch('/api/commands', prepareRequestOptions({ token, query })).then(response => response.json()).then(result => {
    console.debug('HTTP Call: settingsLoadInitData response', result);

    const [users] = result;
    const [user] = users.result;
    users.success && dispatch(setAuthUserData({ user }));
  }).catch(error => console.log('error', error));
};