import { createSelector } from 'reselect';

const getSnackbarSection = state => state.snackbar;

const getSnackbarNotifications = createSelector(
    [getSnackbarSection],
    (snackbar) => snackbar.notifications,
);

export {
  getSnackbarSection,
  getSnackbarNotifications,
};
