import { types } from '../actions/fibery';

/**
 * Initial state of modal interface
 */
const initialState = {
  rows: [],
  lastUpdate: new Date().toISOString(),
  status: 'loading',
};

const reductions = {
  [types.setTrackingTableRows]: (state, { rows }) => {
    return {
      ...state,
      rows,
    };
  },

  [types.setTrackingTableRowsLastUpdate]: (state, { lastUpdate }) => {
    return {
      ...state,
      lastUpdate: lastUpdate,
    };
  },

  [types.setTrackingTableRowsStatus]: (state, { status }) => {
    return {
      ...state,
      status,
    };
  },
};

export {
  reductions,
  initialState,
};
